@import 'https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Inter&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap';
@keyframes show {
  0% {
    -webkit-transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@keyframes hide {
  0% {
    -webkit-transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-100%);
  }
}
@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes optionShow {
  0% {
    margin-left: -20px;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.waves {
  width: 100%;
  height: 15vh;
  min-height: 100px;
  max-height: 150px;
}

.wave-bottom {
  width: 100%;
  background: var(--theme);
  animation: move-height 8s ease-in-out infinite;
}

@keyframes move-height {
  0% {
    height: 0px;
  }
  25% {
    height: 35px;
  }
  50% {
    height: 0px;
  }
  75% {
    height: 35px;
  }
  100% {
    height: 0px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.parallax {
  > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    &:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }
    &:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }
    &:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }
    &:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }
  }
}
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}
* {
  box-sizing: border-box;
}
#Layer2 {
  transform: translateX(-50%) translateY(-50%);
  animation: Layer2__x__1 2500ms 0ms linear forwards,
    Layer2__x__2 1000ms 2500ms linear forwards,
    Layer2__x__3 4000ms 3500ms linear forwards,
    Layer2__y__1 2500ms 0ms linear forwards,
    Layer2__y__2 1000ms 2500ms linear forwards,
    Layer2__y__3 4000ms 3500ms linear forwards;
}

@keyframes Layer2__x__1 {
  from {
    left: 1397.8256px;
  }

  to {
    left: 1397.8256px;
  }
}
@keyframes Layer2__x__2 {
  from {
    left: 1397.8256px;
  }

  to {
    left: 1397.8256px;
  }
}
@keyframes Layer2__x__3 {
  from {
    left: 1397.8256px;
  }

  to {
    left: -106.1744px;
  }
}
@keyframes Layer2__y__1 {
  from {
    top: -140.1088px;
  }

  to {
    top: -140.1088px;
  }
}
@keyframes Layer2__y__2 {
  from {
    top: -140.1088px;
  }

  to {
    top: -140.1088px;
  }
}
@keyframes Layer2__y__3 {
  from {
    top: -140.1088px;
  }

  to {
    top: 1175.8912px;
  }
}

#Layer3 {
  transform: translateX(-50%) translateY(-50%);
  animation: Layer3__x__1 1500ms 0ms linear forwards,
    Layer3__x__2 5000ms 1500ms linear forwards,
    Layer3__y__1 1500ms 0ms linear forwards,
    Layer3__y__2 5000ms 1500ms linear forwards;
}

@keyframes Layer3__x__1 {
  from {
    left: 1315.0634px;
  }

  to {
    left: 1315.0634px;
  }
}
@keyframes Layer3__x__2 {
  from {
    left: 1315.0634px;
  }

  to {
    left: -197.9366px;
  }
}
@keyframes Layer3__y__1 {
  from {
    top: -272.2486px;
  }

  to {
    top: -272.2486px;
  }
}
@keyframes Layer3__y__2 {
  from {
    top: -272.2486px;
  }

  to {
    top: 1387.7514px;
  }
}

#Layer4 {
  transform: translateX(-50%) translateY(-50%);
  animation: Layer4__x__1 5000ms 0ms linear forwards,
    Layer4__y__1 5000ms 0ms linear forwards;
}

@keyframes Layer4__x__1 {
  from {
    left: 1521.2182px;
  }

  to {
    left: -317.7818px;
  }
}
@keyframes Layer4__y__1 {
  from {
    top: -475.5718px;
  }

  to {
    top: 1458.4282px;
  }
}

#Layer5 {
  transform: translateX(-50%) translateY(-50%);
  animation: Layer5__x__1 2500ms 0ms linear forwards,
    Layer5__x__2 5000ms 2500ms linear forwards,
    Layer5__y__1 2500ms 0ms linear forwards,
    Layer5__y__2 5000ms 2500ms linear forwards;
}

@keyframes Layer5__x__1 {
  from {
    left: 1725.9697px;
  }

  to {
    left: 1725.9697px;
  }
}
@keyframes Layer5__x__2 {
  from {
    left: 1725.9697px;
  }

  to {
    left: -173.0303px;
  }
}
@keyframes Layer5__y__1 {
  from {
    top: -414.6962px;
  }

  to {
    top: -414.6962px;
  }
}
@keyframes Layer5__y__2 {
  from {
    top: -414.6962px;
  }

  to {
    top: 1298.3038px;
  }
}

:root {
  --scrollbar-bg: rgba(255, 255, 255, 0.596);
  --scrollbar-bg1: rgba(255, 255, 255, 0.3);
  --card-quantity: 0;
  --cart-h: 0;
  --cart-count: 0;
  --cart-con: calc((var(--cart-h) + 20px) * var(--cart-count));
  --footer-h: 0;
  --fill: #fff;
  --theme: #f8fbff;
  --backup: rgba(147, 39, 143, 1);
  --bg: #fff;
  --text: #000;
  --svg-fill: #000;
  --gradiant: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --input-bg: #fff;
  --msg-outer-box: 0;
  --primary: #275efe;
  --primary-light: #7699ff;
  --dark: #1c212e;
  --grey-dark: #3f4656;
  --grey: #6c7486;
  --grey-light: #cdd9ed;
  --white: #fff;
  --green: #16bf78;
  --sand: #dcb773;
  --sand-light: #edd9a9;
}

.btn-copy {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

[data-theme='dark'] {
  --bg: #202020;
  --fill: #809fb8;
  --text: #fff;
  --svg-fill: #fff;
  --gradiant: linear-gradient(180deg, #202020 0%, #20202000 100%);
  --input-bg: #2c2c2c;
}

// .main {
//   &.bg {
//     background: var(--bg);
//   }
//   &.text {
//     color: var(--text);
//   }
//   &.fill {
//     fill: var(--svg-fill);
//   }
//   &.input {
//     background: var(--input-bg);
//   }
// }

.main-bg {
  background: url(./static/main-bg.png);
  background-size: cover;
  background-position: 100% 0;
}

.theme {
  &.fill {
    fill: #93278f;
  }
  &.bg {
    background: var(--theme);
  }
  &.main-bg {
    background: var(--bg);
  }
  &.text {
    color: var(--theme);
  }
  &.border {
    border: 2px solid var(--theme);
  }
}

.billing-con {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 25%));
  grid-template-rows: repeat(auto-fill, minmax(100px, 50%));
  flex: 1;
  align-items: center;
  gap: 10px 0;
}

.bg-theme {
  background-color: var(--theme);
}

.text-theme {
  color: var(--theme);
}

.nav-option {
  transition: all 0.2s;
  // color: #ffffff;
  border-radius: 0.5rem;
  font-weight: 500 !important;
  padding: 1rem 1.25rem;
  > {
    svg {
      fill: var(--fill);
    }
  }

  &:hover {
    background-color: var(--theme);
    color: #292c38;
    svg {
      fill: #9b24bf !important;
      color: #9b24bf !important;
    }
    --fill: #fff;
  }
  &.on {
    color: #292c38;
    background-color: var(--theme);
    > {
      svg {
        fill: #9b24bf !important;
        color: #9b24bf !important;
      }
    }
  }
}

.nav-inner-option {
  @extend .nav-option;
  width: 90%;
  align-self: flex-end;
  border-radius: 0.75rem;
}
.nav-inner-option-2 {
  @extend .nav-option;
  width: 80%;
  align-self: flex-end;
  border-radius: 0.75rem;
}

.nav-svg {
  fill: #809fb8;
  transition: all 0.2s;
  &[data-on='true'] {
    fill: #fff;
  }
  &on {
    fill: #fff;
  }
}

.noti-box {
  position: absolute;
  min-width: 350px;
  width: 100%;
  max-width: 400px;
  top: 15px;
  right: 10px;
  background-color: white;
  height: auto;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  padding-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  z-index: 100;
}
.address-card {
  position: absolute;
  height: 200%;
  width: 100%;
  top: -50%;
  left: -50%;
  transform: rotate(20deg);
  background-color: #1a21bf;
  z-index: 10;
}

.record-con {
  display: none;
  &[data-show='true'] {
    display: flex;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  // background: #fff;
  background-color: #93278f;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.btn-order-option {
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
  &[data-enable='true'] {
    opacity: 1;
    pointer-events: all;
  }
}

.selectWallet-wrapper {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  &[data-toggle='true'] {
    visibility: visible;
    opacity: 1;
  }
}

.selectWallet-con {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  &[data-toggle='true'] {
    visibility: visible;
    opacity: 1;
  }
  &[data-done='true'] {
    width: fit-content;
    padding: 4rem 3.5rem;
  }
}

.scroll-shadow {
  // background: rgb(255, 255, 255);
  background: var(--gradiant);
  width: 100%;
  height: 1.5rem;
  position: absolute;
  bottom: -1.45rem;
  &[data-cart='true'] {
    top: -10px;
    height: 2.25rem;
    z-index: 100;
  }
  &[data-bottom='true'] {
    top: auto;
    bottom: -10px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.home-bg {
  background-image: url('./static/purple_square_bg-ai.webp');
  background-size: cover;
  background-repeat: no-repeat;
  animation: bgShow 3s forwards;
}
.lookup-bg {
  background-image: url('./static/grey_bg2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  animation: bgShow 3s forwards;
}
@keyframes bgShow {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.btn-message-options {
  transition: all 0.2s;
  svg {
    fill: #809fb8;
  }
  &:hover {
    background-color: var(--theme);
    svg {
      fill: #fff;
    }
  }
}

.gradient-shadow {
  box-shadow: 0px 19px 45px rgba(237, 65, 230, 0.2),
    inset 0px 1.9926px 9.96302px rgba(255, 255, 255, 0.52);
}

.gradient-box {
  position: relative;
  border-radius: 8px;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    border-radius: 8px;
    padding: 2px;
    background: linear-gradient(120deg, #78efff, #ff86e1);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  &[data-on='true'] {
    &::after {
      background: linear-gradient(120deg, #ac64ff, #00e0ff);
    }
    background: linear-gradient(120deg, #ac64ff, #00e0ff);
  }
  &.gradient-box-rounded-2xl {
    &::after {
      border-radius: 1rem;
    }
  }
  &.gradient-box-rounded-full {
    &::after {
      border-radius: 50rem;
    }
  }
  &.gradient-box-hover {
    &:hover {
      &::after {
        background: linear-gradient(120deg, #ac64ff, #00e0ff);
      }
      background: linear-gradient(120deg, #ac64ff, #00e0ff);
    }
  }
}

.gradient-box-1 {
  @extend .gradient-box;
  &::after {
    background: linear-gradient(
      120.72deg,
      rgba(26, 227, 255, 0.1) -6.55%,
      rgba(154, 68, 255, 0.1) 43.21%,
      rgba(255, 45, 204, 0.1) 86.21%
    );
  }
}

.gradient-bg-1 {
  background: linear-gradient(
    120.72deg,
    rgba(26, 227, 255, 0.04) -6.55%,
    rgba(154, 68, 255, 0.04) 43.21%,
    rgba(255, 45, 204, 0.04) 86.21%
  );
}

.bg-gradient-available {
  background: linear-gradient(90deg, #84fab0 0%, #8fd3f4 100%);
}
.bg-gradient-unavailable {
  background: linear-gradient(90deg, #c02c24 0%, #b32922 100%);
}

.bg-card {
  background-image: url('./static/bg-card.png');
  background-size: cover;
}
.bg-card {
  color: white;
}

.blockchain-wrapper {
  display: grid;
  grid-template-columns: repeat(7, minmax(120px, 1fr));
  gap: 30px;
  grid-auto-flow: dense;
}

.blockchain-card-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
}

.blockchain-select-group {
}

.tld-selection-label {
  margin-bottom: 1rem;
  font-size: 18px;
  margin-top: 1rem;
}
.blockchain-card {
  min-width: 120px;
  max-width: 240px;
  display: flex;
  flex: 1 1 0%;
  border-width: 0;
}

.tldCard {
  min-width: 140px;
  display: flex;
  flex: 1 1 0%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.tldCard.Mui-selected {
  background-color: #bb29bb !important;
  color: white !important;
}

.tld-name {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
}

.v2-domain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: linear-gradient(
    120.72deg,
    rgba(26, 227, 255, 0.04) -6.55%,
    rgba(154, 68, 255, 0.04) 43.21%,
    rgba(255, 45, 204, 0.04) 86.21%
  );
  z-index: -1;
  //background-image: url('./static/bg-card.png');
  //background-size: cover;
}

.reseller-con {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40%);
  transition: all 0.3s;
  height: 192px;
  width: 550px;
  background: #fff;
  overflow: hidden;
  &[data-show='true'] {
    transform: translate(-50%, -50%);
  }
  &[data-intro='true'] {
    width: 400px;
    height: 255px;
  }
  &[data-action='corporate'],
  &[data-action='individual'] {
    overflow-y: auto;
    height: 930px;
  }
  &[data-action='submit'] {
    height: 500px;
    min-width: 450px;
    width: 450px;
  }
  &[data-action='code'] {
    height: 290px;
    min-width: 400px;
    width: 400px;
  }
}

.reseller-record-con {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.btn-reseller {
  svg {
    display: none;
  }
  &.copy {
    > {
      svg {
        display: block;
      }
      p {
        display: none;
      }
    }
  }
}

.pending-con {
  opacity: 0;
  &[data-show='true'] {
    animation: pendingShow 0.5s forwards 0.2s;
  }
}

@keyframes pendingShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.reseller-con-outer {
  visibility: hidden;
  transition: all 0.2s;
  opacity: 0;
  &[data-show='true'] {
    visibility: visible;
    opacity: 1;
  }
}

.web3modal-modal-lightbox,
.web3modal-modal-container,
.web3modal-modal-hitbox {
  z-index: 500 !important;
  transition: all 0.2s;
}
.web3modal-modal-card {
  z-index: 501 !important;
}

.scrollbar-hidden {
  ::-webkit-scrollbar {
    display: none;
  }
}

.cart-h {
  flex: 1;
  &::after {
    content: '';
    width: 100%;
    height: 2rem;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    border: 16px solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar {
    width: 0px;
    border-radius: 24px;
  }
}

.btn-save {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-15px);
  transform-origin: center left;
  transition: transform 0.3s 0.6s, opacity 0.3s 0.6s;
  > {
    .text {
      opacity: 0;
      visibility: hidden;
    }
  }
  &.show-btn {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: transform 0.3s, opacity 0.3s;
    > {
      .text {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.btn-save-icon {
  opacity: 0;
  transition: opacity 0.1s;
  &.show-btn {
    opacity: 1;
  }
}

.msg-box {
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: all 0.2s;
  &[data-show='true'] {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    // transform: translateY(0%);
  }
}

.msg-box-h {
  height: var(--msg-outer-box);
}

.error-msg {
  opacity: 0;
  transition: opacity 0.2s;
  // width: 100%;
  &.show-msg {
    opacity: 1;
  }
}

.order-con {
  transition: all 0.3s;
}

.card-w {
  width: calc(
    (100% - ((var(--card-quantity) - 1) * 1.25rem)) / var(--card-quantity)
  );
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--theme);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.shows {
  animation: shows 0.7s forwards;
}

@keyframes shows {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shopping-cart-con {
  width: calc(100% + 40px);
  padding-right: 20px;
}

.missingCon {
  z-index: 100;
  width: 100%;
  opacity: 0;
  height: 0;
  cursor: pointer;
  visibility: hidden;
  margin-bottom: 0;
  transition: all 0.3s;
  &[data-show='true'] {
    opacity: 1;
    height: auto;
    visibility: visible;
    margin-bottom: 1.25rem;
  }
  &.cart {
    width: calc(100% - 60px);
    margin: 0 auto;
    margin-top: 0.75rem;
  }
  &.sms {
    width: calc(100% - 60px);
    margin: 0 auto;
    margin-top: 0.75rem;
    z-index: 500;
  }
}

.result-grid {
  grid-template-columns: 1fr;
  gap: 20px;
  border-radius: 0.75rem;
}

.scroll-box {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2.5rem;
    flex-shrink: 0;
  }
}

.domains-bar {
  display: grid;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
}

.domains-grid-con {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  display: grid;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
    background-color: rgb(245, 247, 252);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    &:hover {
      background-color: #d4d4d4;
    }
  }
}

.modal-con {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  &[data-show='true'] {
    visibility: visible;
    opacity: 1;
  }
}
.account-domain-con {
  display: grid;
  grid-auto-columns: minmax(422px, 420px);
  grid-auto-flow: column;
}
.domains-grid-con.sms {
  grid-auto-columns: minmax(250px, 1fr);
  grid-auto-flow: column;
  overflow: auto;
}
.sms-msg-con {
  width: clamp(400px, 40%, 500px);
}
.add-path {
  fill: #17209e;
  transition: all 0.2s;
}
.btn-add-s {
  &:hover {
    > .add-path {
      fill: #fff;
    }
  }
}
.account-b-con {
  flex-direction: row;
}

::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  border-radius: 0.75;
  border: 1px solid transparent;
}
::-webkit-scrollbar-thumb {
  @apply bg-gradient-to-br from-[#eb21f2] to-[#8510dd];
  border: 8px solid transparent;
  background-clip: padding-box;
  border-radius: 20px;
}
.domain-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  gap: 2rem;
}
.navbar {
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
}
.wallet-bar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.load-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkout-box {
  width: 1.5rem !important;
  height: 1.5rem !important;
  display: grid;
  place-items: center;
}
.check.check-box {
  top: 10px;
}
.checkout-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
//
.l-nav-bg {
  background-image: url(./static/bg-nav.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 85% 0%;
}
.out-con {
  &:hover {
    > .nav-options {
      &:after {
        width: 100%;
      }
    }
  }
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
th {
  padding: 15px 15px;
  font-weight: 500;
}
input {
  -webkit-user-select: text;
}
.load {
  display: none;
  justify-content: center;
  width: 100%;
}
.dones {
  display: none;
  justify-content: center;
  width: 100%;
}
.bg-website {
  background-color: rgb(139 92 246);
  background-image: linear-gradient(
    to right,
    rgb(169, 132, 255) 0%,
    rgb(16 185 129) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  text-decoration: none;
  color: transparent;
  font-weight: 700;
}
.App {
  background-image: url('./static/bg.webp');
}
.bold {
  font-weight: bold;
}
.show {
  animation: show 0.2s forwards;
}
.hide {
  animation: hide 0.2s forwards;
}

.wallet-con {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;
  display: flex;
  &[data-done='true'] {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

.done-con {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s 0.3s;
  display: none;
  &[data-done='true'] {
    visibility: visible;
    opacity: 1;
    display: flex;
  }
}

.control {
  font-family: arial;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:focus {
      ~ {
        .control_indicator {
          background: #cccccc;
        }
      }
    }
    &:checked {
      ~ {
        .control_indicator {
          background: #494de7;
          &:after {
            display: block;
          }
        }
        .control_indicator1 {
          background: rgb(59, 130, 246);
        }
      }
    }
    &:disabled {
      ~ {
        .control_indicator {
          background: #e6e6e6;
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .control_indicator {
          background: #cccccc;
        }
      }
    }
  }
}
.control_indicator {
  position: relative;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  &:after {
    box-sizing: unset;
    content: '';
    display: none;
    margin-top: -3px;
  }
}

.now {
  opacity: 0;
  visibility: hidden;
  &[data-show='true'] {
    opacity: 1;
    visibility: visible;
  }
}

.pays {
  opacity: 0;
  visibility: hidden;
  height: 325px;
  &[data-show='true'] {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
}

:root {
  --translateX: -50%;
  --translateY: -40%;
}

.special-label {
  display: none !important;
}

.react-tel-input {
  width: auto !important;
}

.form-control {
  height: 50px !important;
  width: 250px !important;
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(var(--translateX), var(--translateY));
  // transform-origin: center top;
  transition: all 0.3s;
  width: fit-content;
  height: auto;
  background: #fff;
  z-index: 410;
  border-radius: 1rem;
  opacity: 0;
  visibility: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  &[data-show='true'] {
    visibility: visible;
    opacity: 1;
    transform: translate(var(--translateX), var(--translateX));
  }
}

.bg-bound {
  transition: visibility 0.3s, opacity 0.3s;
  // visibility: hidden;
  // opacity: 0;
  &[data-show='true'] {
    visibility: visible;
    opacity: 1;
  }
}
.control-checkbox {
  .control_indicator {
    &:after {
      left: 6px;
      top: 3px;
      width: 4px;
      height: 8px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  input {
    &:disabled {
      ~ {
        .control_indicator {
          &:after {
            border-color: #7b7b7b;
          }
        }
      }
    }
    + {
      .control_indicator {
        &::before {
          animation: s-ripple 250ms ease-out;
        }
      }
    }
    &:checked {
      + {
        .control_indicator {
          &::before {
            animation-name: s-ripple-dup;
          }
        }
      }
    }
  }
}
.select {
  background: black;
}
.nav {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.open-san {
  font-family: 'Open Sans', sans-serif;
}
.input-contact {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  outline: none;
}
.btn-contact {
  font-family: 'Quicksand', sans-serif;
}
.icon {
  filter: brightness(0) invert(1);
  cursor: pointer;
}
.btn-user {
  &:hover {
    ~ {
      .user-option {
        display: flex;
        > .option-in {
          display: block;
        }
      }
    }
  }
}
.form-control {
  border: 1px solid #d1d5db !important;
  border-radius: 1.25rem;
}
.btn-top-option {
  cursor: pointer;
  transition: all 0.2s;
  > {
    svg {
      fill: #809fb8;
    }
  }
  &:hover {
    background-color: var(--theme);
    svg {
      fill: #fff;
    }
  }
}
.user-option {
  &:hover {
    display: flex;
    > .option-in {
      display: block;
    }
  }
  display: none;
  > div {
    &:nth-child(1) {
      animation-delay: 0.15s;
    }
    &:nth-child(2) {
      animation-delay: 0.25s;
    }
    &:nth-child(3) {
      animation-delay: 0.35s;
    }
    &:nth-child(4) {
      animation-delay: 0.45s;
    }
  }
}

.btn-con {
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  &:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

.btn-now {
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  // background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  background-image: linear-gradient(
    to right,
    #93278f,
    #764ba2,
    #8e37d7,
    #667eea
  );
  // box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  &:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}
.btn-web {
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  background-image: linear-gradient(
    to right,
    rgb(169, 132, 255),
    #40e495,
    #30dd8a,
    rgb(169, 132, 255)
  );
  box-shadow: 0 4px 15px 0 rgba(198, 174, 255, 0.75);
}
.btn-crypto {
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  );
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  &:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}
.btn-checkout {
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
  &:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}
.btn-add {
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  background-image: linear-gradient(
    to right,
    #fc6076,
    #ff9a44,
    #ef9d43,
    #e75516
  );
  box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
  &:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}
.section {
  height: 100%;
  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
}
.com-section {
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
}
.btn-cart {
  &:focus {
    ~ {
      .out-cart {
        transform: translateX(0);
      }
    }
  }
}
.out-cart {
  transform: translateX(100%);
  transition: 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 100;
  &:focus-within {
    transform: translateX(0);
  }
}
.option-in {
  text-align: center;
  display: none;
  animation: optionShow 0.3s forwards;
  opacity: 0;
}
.left-nav-height {
  height: calc(100vh - 96px);
}

.search-warn {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s;
  &.visible {
    visibility: visible;
    opacity: 1;
    animation: warning 0.3s forwards;
  }
}

.tests {
  &[data-width] {
    transition-delay: attr(data-width number, 0);
  }
}

.reseller-info-con {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.msg-bar {
  height: 65px;
  flex-direction: column;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 200;
  &[data-createMsg='true'] {
    height: 420px;
    overflow: visible;
    &[data-type='domain'] {
      transition: all 0s;
      height: 380px;
    }
  }
}

.msg-option {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--theme);
  transition: all 0.3s;
  border-radius: 0.25rem;
  &[data-type='domain'] {
    left: 50%;
  }
}

.msg-option-inner {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s 0.2s;
  &[data-createMsg='true'] {
    opacity: 1;
    visibility: visible;
  }
}

.msg-option-phone {
  color: #000;
  &[data-type='sms'] {
    color: #fff;
  }
}

.msg-option-domain {
  color: #000;
  &[data-type='domain'] {
    color: #fff;
  }
}

.emoji-con {
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: bottom right;
  transition: all 0.3s;
  &[data-show='true'] {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes warning {
  0% {
    transform: translateX(-5px);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 1730px) {
  .account-r-con {
    max-height: inherit;
  }
  .account-b-con {
    flex-direction: column;
  }
}
@media screen and (max-width: 1280px) {
  .section.result {
    height: auto;
    max-height: inherit;
  }
  .reseller-record-con {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
@media screen and (max-width: 768px) {
  .user-option {
    &:hover {
      height: 150px;
      visibility: visible;
      margin-top: 15px;
    }
    overflow: hidden;
    display: block;
    height: 0;
    position: relative;
    transition: all 0.3s;
    visibility: hidden;
    > div {
      &:nth-child(1) {
        animation-delay: 0.15s;
      }
      &:nth-child(2) {
        animation-delay: 0.25s;
      }
      &:nth-child(3) {
        animation-delay: 0.35s;
      }
      &:nth-child(4) {
        animation-delay: 0.45s;
      }
    }
  }
  .btn-user {
    &:hover {
      ~ {
        .user-option {
          height: 150px;
          visibility: visible;
          margin-top: 15px;
          > .option-in {
            display: block;
          }
        }
      }
    }
  }
  .option-in {
    text-align: center;
    border: 0;
    display: none;
    animation: optionShow 0.3s forwards;
    opacity: 0;
  }
}
@media screen and (min-height: 1200px) {
  .contact {
    height: calc(100vh - (300px + 468px));
  }
}

.leftNav {
  display: flex;
  flex-direction: column;
  z-index: 1000;
  &::-webkit-scrollbar {
    display: none;
  }
}

.right-con {
  // padding: 1.25rem;
}

.search-con {
  width: 250px;
}

.nav-inner {
  display: none;
  z-index: 0;
}

.leftNav-outer {
  position: relative;
}
.btn-option {
  visibility: hidden;
}

.top-nav-con {
  display: none;
}

@media screen and (max-width: 1280px) {
  .btn-option {
    visibility: visible;
  }
  .leftNav-outer {
    position: fixed;
    margin-left: -400px;
    transition: all 0.2s;
    z-index: 510;
    > {
      .leftNav {
        padding-right: 1.25rem;
      }
    }
    &[data-show='true'] {
      margin-left: 0px;
    }
  }
  .top-nav-con {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .top-nav-con {
    display: none;
  }
}

.cart-bottom {
  &::after {
    content: '';
    width: 100%;
    height: 2.5rem;
    flex-shrink: 0;
  }
}

.btn-send-type {
  margin-left: 2px;
  transition: all 0.2s;
  &[data-send='true'] {
    margin-left: 22px;
  }
}

.txt-send-type {
  width: 56px;
  overflow-x: hidden;
  flex-wrap: nowrap;
  > {
    div {
      margin-left: 0px;
      transition: all 0.2s;
      &[data-send='true'] {
        margin-left: -56px;
      }
    }
  }
}

#WEB3_CONNECT_MODAL_ID,
.web3modal-modal-card,
.web3modal-modal-container,
.web3modal-modal-lightbox {
  z-index: 1000 !important;
}

.icon-align-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bind-con {
  transition: all 0.3s;
  height: 70px;
  &[data-binding='true'] {
    height: 130px;
  }
  &[data-done='true'] {
    height: 70px;
  }
}

.binding-con {
  transition: all 0.3s;
  &[data-binding='true'] {
    transform: translateX(-150px);
    opacity: 0;
    visibility: hidden;
  }
}

.loading {
  transform: translateX(150px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  &[data-binding='true'] {
    transform: translateX(0px);
    visibility: visible;
    opacity: 1;
  }
  &[data-loading='true'] {
    transform: translateX(-150px);
    opacity: 0;
    visibility: hidden;
  }
}

.bind-bg,
.bind-main-con {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  &[data-display='true'] {
    opacity: 1;
    visibility: visible;
  }
}

.bind-main-con {
  transform: translateY(200px);
  &[data-display='true'] {
    transform: translateY(0);
  }
}

.binding-msg-con {
  display: flex;
  align-items: center;
  transform: translateX(150px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  &[data-done='true'] {
    transform: translateX(0px);
    visibility: visible;
    opacity: 1;
  }
}

.sender-con {
  &::-webkit-scrollbar {
    width: 16px;
    height: 8px;
    border-radius: 0.75;
    border: 1px solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
  }
}

.changeBg {
  &[data-change='true'] {
    background: rgb(29 78 216);
  }
}

@media screen and (max-width: 768px) {
  .leftNav-outer {
    margin-left: 0px;
    box-shadow: initial;
  }
  .leftNav {
    // display: none;
    width: 100%;
  }

  .search-con {
    width: 100%;
  }
}

.total-box {
  position: relative;
}

.msg-right-con {
  min-width: 400px;
}

.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

@media (max-width: 768px) {
  .nav-option {
    border-radius: 0;
  }
  .right-con {
    padding: 0;
    padding-top: 60px;
  }

  .msg-left-con {
    display: flex;
    &[data-msg='true'] {
      display: none;
    }
  }

  .msg-right-con {
    display: none;
    &[data-msg='true'] {
      display: flex;
    }
  }

  .sms-msg-con {
    width: 100%;
  }

  .msg-outer-box {
    min-width: inherit;
  }
  .billing-con {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .pays {
    opacity: 0;
    visibility: hidden;
    height: 600px;
    &[data-show='true'] {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
  .total-box {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2.5rem;
      bottom: -100px;
    }
  }
  .reseller-con {
    min-width: 95%;
    width: auto;
    &[data-action='submit'] {
      min-width: 95%;
      width: auto;
    }
  }
  .leftNav {
    z-index: 600;
  }
  .nav-inner-option {
    width: 100%;
    border-radius: 0;
  }
  .home-bg {
    background-position: 40% 0%;
  }
}

.domain-icon-con {
  > {
    .domain-icon {
      color: rgb(147 51 234);
    }
  }

  &:hover {
    > {
      .domain-icon {
        color: #fff;
      }
    }
  }
}

.btn-select {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  &[data-show='false'] {
    opacity: 1;
    visibility: visible;
  }
}
.transfer-domain-con {
  &:hover {
    :nth-child(1) {
      color: #fff;
    }
  }
}

.transfer-domain-icon {
  &[data-selected='true'] {
    color: #fff !important;
  }
}

.dropdown {
  position: absolute;
  top: 38px;
  left: -1px;
  border: 1px solid rgb(197, 197, 197);
  background: #fff;
  padding: 0;
  color: #0062cc;
  text-align: left;
  border-radius: 0.25rem;
}
.dropdown > p {
  margin: 0;
  padding: 0.375rem 0.88rem;
  border-bottom: 1px solid rgb(235, 235, 235);
  min-width: 140px;
}
.dropdown > p:hover {
  color: #fff;
  background-color: #0062cc;
}
.dropdown > p:last-child {
  border-bottom: 0 none;
}

@media screen and (max-height: 1040px) {
  .reseller-con {
    &[data-action='individual'],
    &[data-action='corporate'] {
      min-height: 500px;
      height: 85%;
      overflow-y: auto;
    }
    &::-webkit-scrollbar-thumb {
      border: 6px solid transparent;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar {
      width: 20px;
      border-radius: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .msg-right-con {
    min-width: 0px;
  }
  .nav-inner {
    display: block;
    z-index: 800;
  }
  .leftNav-inner {
    position: fixed;
    user-select: none;
    flex-direction: column;
    top: 60px;
    left: -110%;
    z-index: 500;
    height: 100%;
    transition: all 0.3s;
    &[data-show='true'] {
      left: 0;
    }
  }
  @media (max-height: 680px) {
    .leftNav-inner {
      height: calc(100% - 90px);
      overflow-y: auto;
    }
  }
  .account-domain-con {
    display: grid;
    grid-auto-columns: 100%;
    grid-auto-flow: column;
  }
}

.btn-delete {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  height: 0;
  margin-top: 0;
  &[data-show='true'] {
    opacity: 1;
    visibility: visible;
    height: 44px;
    margin-top: 1rem;
  }
}

//icon chain start
//.container-icon {
//  position: relative;
//  width: 50px;
//}
//
//.image-icon {
//  display: block;
//  width: 50px;
//  height: 50px;
//}
//
//.overlay-icon {
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  height: 50px;
//  width: 50px;
//  opacity: 0;
//  transition: .5s ease;
//  background-color: #008CBA;
//}
//
//.container-icon:hover .overlay-icon {
//  opacity: 1;
//}
//
//.text-icon {
//  color: white;
//  font-size: 10px;
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  -webkit-transform: translate(-50%, -50%);
//  -ms-transform: translate(-50%, -50%);
//  transform: translate(-50%, -50%);
//  text-align: center;
//}

//icon chain end

.domain-select {
  + {
    .domain-del {
      opacity: 0;
      visibility: hidden;
    }
  }
  &[data-option='true'] {
    + {
      .domain-del {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-logo {
    width: 80vw;
  }
}

input {
  margin-right: 16px;
  &:required {
    &::after {
      content: '*';
      color: red;
      margin-left: 20px;
    }
  }
}


.payment-icon{
  object-fit: scale-down;
  height: 48px;
  width: 48px;
  background-color:white;
  display: flex;
  align-items: center;
}

ul.payment-ul {

  list-style: none; /* Remove default bullets */
}

ul.payment-ul li::before {
  margin-bottom: 1rem;
  content: "\2022";
  color: #8A2BE2;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
}
